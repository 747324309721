import * as React from "react"
import PropTypes from "prop-types"

import "./layout.css"
import HomeHeader from "./HomeHeader"
import Header from "./Header"

import styled from '@emotion/styled'
import Footer from "./Footer"
import MobileFooter from "./Footer/MobileFooter"
import { navigate } from "gatsby"
import Fab from '@mui/material/Fab'
import { useLocation } from "@reach/router"
import { Typography } from "@mui/material"
import SendIcon from '@mui/icons-material/Send'
import { Color } from "../util/Color"

const HomeLayout = ({ children }) => {
  const location = useLocation()
  const [scrollPosition, setScrollPosition] = React.useState(0);
  const handleScroll = () => {
      const position = window.pageYOffset;
      setScrollPosition(position);
  };

  React.useEffect(() => {
      window.addEventListener('scroll', handleScroll, { passive: true });

      return () => {
          window.removeEventListener('scroll', handleScroll);
      };
  }, []);

  const [view, setView] = React.useState('PC')

  React.useEffect(() => {
    const w = window.innerWidth
    setView((() => {
      if (1440 < w) return "PC"
      else if (960 < w) return "TABLET"
      else return "MOBILE"
    })())
  })

  const getFooter = () => {
    if (view === 'MOBILE') {
      return <MobileFooter />
    }
    return <Footer />
  }
  return (
    <main>
      {scrollPosition < 430 ? <HomeHeader /> : scrollPosition < 1240 || <Header />}
      <Contents>
        {children}
      </Contents>
      {view === 'MOBILE' && location.pathname.includes('/contact') === false && (
        <SFab color="primary" aria-label="add" onClick={() => navigate('/contact')}>
          <Wrapper>
            <span className="material-symbols-outlined">send</span>
            <STypography variant="h5">
              Contact
            </STypography>
          </Wrapper>
        </SFab>
      )}
      {getFooter()}
    </main>
  )
}

HomeLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default HomeLayout

const Contents = styled('div')({
  marginBottom: '80px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
})

const STypography = styled(Typography)(({view}) => ({
  fontFamily: 'Roboto',
  fontFeatureSettings: '"palt" 1',
  fontSize: '8px',
  fontWeight: 700,
  marginTop: '-8px'
}))

const Wrapper = styled('div')({
})
const SFab = styled(Fab)({
  position: 'fixed',
  right: '24px',
  bottom: '24px',
  width: '80px',
  height: '80px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: `${Color.orange} !important`
})
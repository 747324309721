import * as React from "react"
import styled from "@emotion/styled"
import { StaticImage } from "gatsby-plugin-image"

const Top = ({view}) => {
  return (
  <Root view={view}>
    {view === 'PC' ? <StaticImage src="./service.png" alt="top"/> : view === 'TABLET' ? <StaticImage src="./tab_service.png" alt="top"/> : <StaticImage src="./mobile_service.png" alt="top"/>}
    {view === 'MOBILE' && <Title><StaticImage src={`./service_text.png`} alt="service"/></Title>}
  </Root>
)
}

export default Top

const Root = styled('div')(({view}) => ({
  width: '100%',
  '& > div': {
    width: '100%',
    height: '100vh'
  }
}))

const Title = styled('div')({
  zIndex: '999',
  position: 'absolute',
  top: '40vh',
  left: '18px',
  width: 'calc(100% - 18px) !important',
  '& > div': {
    width: 'calc(100% - 18px)'
  }
})

import * as React from "react"

import styled from "@emotion/styled"
import { StaticImage } from "gatsby-plugin-image"
import { keyframes } from "@emotion/react"

import HomeLayout from "../components/HomeLayout"
import Seo from "../components/seo"
import Top from "../components/feature/Service/Top"
import LeftTitle from "../components/feature/Home/LeftTitle"
import Title from "../components/feature/Service/Title"
import { Color } from "../util/Color"

const description = ""

const IndexPage = () => {
  const [view, setView] = React.useState("PC")

  React.useEffect(() => {
    const w = window.innerWidth
    setView(
      (() => {
        if (1440 < w) return "PC"
        else if (960 < w) return "TABLET"
        else return "MOBILE"
      })()
    )
  })

  return (
    <HomeLayout>
      <Seo title="Service" description={description} />
      <Top view={view} />
      <Contents view={view}>
        <Margin view={view}>
          <LeftTitle
            en="BLOCKCHAIN"
            ja="ブロックチェーン事業"
            id="BlockChain"
            view={view}
          />
        </Margin>
        <Juggle view={view} />
        <Machi view={view} />
        <Book view={view} />
        <DX view={view} />

        <Margin view={view}>
          <LeftTitle en="PLM" ja="PLM事業" id="PLM" view={view} />
        </Margin>
        <PLM1 view={view} />
        <PLM2 view={view} />
        <PLM3 view={view} />
      </Contents>
    </HomeLayout>
  )
}

const Juggle = ({ view }) => {
  if (view === "MOBILE") {
    return (
      <MWrapper>
        <Title en="Juggle" ja="Juggle" view={view} />
        <ImageWrapper view={view}>
          <StaticImage src="../images/service/m_juggle.png" alt="juggle" />
        </ImageWrapper>
        <TextWrapper>
          <Text view={view}>
            Juggleはブロックチェーン技術を活用した次世代ファイル共有アプリです。
            PPAP問題の代替手段にもなり、パスワードを意識せず安心・安全でかつ容易なファイル共有が実現できます。
          </Text>
          <Link href="https://www.juggle.jp/" target="_blank" view={view}>
            Juggleの詳細はこちらより
            <IconWrapper>
              <span className="material-icons">arrow_forward</span>
            </IconWrapper>
          </Link>
        </TextWrapper>
      </MWrapper>
    )
  }
  return (
    <Wrapper reverse={false}>
      <Colomn>
        <Title en="Juggle" ja="Juggle" view={view} />
        <Text view={view}>Juggleはブロックチェーン技術を活用した</Text>
        <Text view={view}>次世代ファイル共有アプリです。</Text>
        <Text view={view}>
          PPAP問題の代替手段にもなり、パスワードを意識せず
        </Text>
        <Text view={view}>
          安心・安全でかつ容易なファイル共有が実現できます。
        </Text>
        <Link href="https://www.juggle.jp/" target="_blank" view={view}>
          Juggleの詳細はこちらより
          <IconWrapper>
            <span className="material-icons">arrow_forward</span>
          </IconWrapper>
        </Link>
      </Colomn>
      <ImageWrapper view={view}>
        <StaticImage src="../images/service/juggle.png" alt="juggle" />
      </ImageWrapper>
    </Wrapper>
  )
}

const Machi = ({ view }) => {
  if (view === "MOBILE") {
    return (
      <MWrapper>
        <Title en="Machi" ja="まちめぐり" view={view} />
        <ImageWrapper view={view}>
          <StaticImage
            src="../images/service/machi_logo.svg"
            alt="まちめぐり"
          />
        </ImageWrapper>
        <TextWrapper>
          <Text view={view}>
            人と地域のつながりをNFTを活用して深める新しいタイプのスタンプラリーです。直感的でシンプルなUIで誰でも参加でき、関係人口創出など地域の盛り上がりを支援します。
          </Text>
          <Link href="https://machimeguri.app/" target="_blank" view={view}>
            まちめぐりの詳細はこちらより
            <IconWrapper>
              <span className="material-icons">arrow_forward</span>
            </IconWrapper>
          </Link>
        </TextWrapper>
      </MWrapper>
    )
  }
  return (
    <Wrapper reverse={true}>
      <Colomn>
        <Title en="Machi" ja="まちめぐり" view={view} />
        <Text view={view}>人と地域のつながりをNFTを活用して深める</Text>
        <Text view={view}>新しいタイプのスタンプラリーです。</Text>
        <Text view={view}>直感的でシンプルなUIで誰でも参加でき</Text>
        <Text view={view}>関係人口創出など地域の盛り上がりを支援します。</Text>
        <Link href="https://machimeguri.app/" target="_blank" view={view}>
          まちめぐりの詳細はこちらより
          <IconWrapper>
            <span className="material-icons">arrow_forward</span>
          </IconWrapper>
        </Link>
      </Colomn>
      <ImageWrapper view={view}>
        <StaticImage src="../images/service/machi_logo.svg" alt="まちめぐり" />
      </ImageWrapper>
    </Wrapper>
  )
}

const Book = ({ view }) => {
  if (view === "MOBILE") {
    return (
      <MWrapper>
        <Title en="Book" ja="書籍" view={view} />
        <ImageWrapper view={view}>
          <StaticImage src="../images/service/book.jpg" alt="book" />
        </ImageWrapper>
        <TextWrapper>
          <Text view={view}>
            当社エンジニア陣が執筆したSymbolブロックチェーンを活用したアプリケーション構築ガイドです。ハンズオン形式でブロックチェーンのユースケースを学びつつWebアプリケーションの構築を学ぶことができます。
          </Text>
          <Link href="https://amzn.asia/d/ffcoL54" target="_blank" view={view}>
            お買い求めはこちらより
            <IconWrapper>
              <span className="material-icons">arrow_forward</span>
            </IconWrapper>
          </Link>
        </TextWrapper>
      </MWrapper>
    )
  }
  return (
    <Wrapper reverse={false}>
      <Colomn>
        <Title en="Book" ja="書籍" view={view} />
        <Text view={view}>
          当社エンジニア陣が執筆したSymbolブロックチェーンを
        </Text>
        <Text view={view}>活用したアプリケーション構築ガイドです。</Text>
        <Text view={view}>
          ハンズオン形式でブロックチェーンのユースケースを学びつつ
        </Text>
        <Text view={view}>Webアプリケーションの構築を学ぶことができます。</Text>
        <Link href="https://amzn.asia/d/ffcoL54" target="_blank" view={view}>
          お買い求めはこちらより
          <IconWrapper>
            <span className="material-icons">arrow_forward</span>
          </IconWrapper>
        </Link>
      </Colomn>
      <ImageWrapper view={view}>
        <StaticImage src="../images/service/book.jpg" alt="book" />
      </ImageWrapper>
    </Wrapper>
  )
}

const Zero = ({ view }) => {
  if (view === "MOBILE") {
    return (
      <MWrapper>
        <Title en="ZERO" ja="ゼロ・カーボン" view={view} />
        <ImageWrapper view={view}>
          <StaticImage src="../images/service/m_zero.png" alt="zero carbon" />
        </ImageWrapper>
        <TextWrapper>
          <Text view={view}>
            地球環境に優しい世界を目指し、環境によいアクションを取った人へのポイント還元や二酸化炭素排出量トレーサビリティの基盤構築をブロックチェーン技術を活用し構築しています。また、以下のSDGsのゴールを目指しています
          </Text>
        </TextWrapper>
      </MWrapper>
    )
  }
  return (
    <Wrapper reverse={true}>
      <Colomn>
        <Title en="ZERO" ja="ゼロ・カーボン" view={view} />
        <Text view={view}>
          地球環境に優しい世界を目指し、環境によいアクションを取った人
        </Text>
        <Text view={view}>
          へのポイント還元や二酸化炭素排出量トレーサビリティの基盤構築
        </Text>
        <Text view={view}>をブロックチェーン技術を活用し構築しています。</Text>
        <Text view={view}>また、以下のSDGsのゴールを目指しています</Text>
        <Row>
          <Image>
            <StaticImage src="../images/sdgs/sdg7.png" alt="sdgs7" />
          </Image>
          <Image>
            <StaticImage src="../images/sdgs/sdg13.png" alt="sdgs13" />
          </Image>
        </Row>
      </Colomn>
      <ImageWrapper view={view}>
        <StaticImage src="../images/service/zero.png" alt="zero carbon" />
      </ImageWrapper>
    </Wrapper>
  )
}

const Wallet = ({ view }) => {
  if (view === "MOBILE") {
    return (
      <MWrapper>
        <Title en="Wallet" ja="3ステップウォレット" view={view} />
        <ImageWrapper view={view}>
          <StaticImage
            src="../images/service/m_3step.png"
            alt="3 step wallet"
          />
        </ImageWrapper>
        <TextWrapper>
          <Text view={view}>
            健常者と同じように自由なタイミングで買い物ができないという問題を解決するプロジェクトです。Symbolブロックチェーンのマルチシグ機能を用いて知的障碍者の方でもネットショッピングやキャッシュレス決済に対応するためのアプリケーション開発や実証実験を行っています
          </Text>
        </TextWrapper>
      </MWrapper>
    )
  }
  return (
    <Wrapper reverse={false}>
      <Colomn>
        <Title en="Wallet" ja="3ステップウォレット" view={view} />
        <Text view={view}>
          健常者と同じように自由なタイミングで買い物ができない
        </Text>
        <Text view={view}>という問題を解決するプロジェクトです。</Text>
        <Text view={view}>Symbolブロックチェーンのマルチシグ機能を用いて</Text>
        <Text view={view}>
          知的障碍者の方でもネットショッピングやキャッシュレス決済に
        </Text>
        <Text view={view}>
          対応するためのアプリケーション開発や実証実験を行っています
        </Text>
        <Row>
          <Image>
            <StaticImage src="../images/sdgs/sdg3.png" alt="sdgs3" />
          </Image>
          <Image>
            <StaticImage src="../images/sdgs/sdg8.png" alt="sdgs8" />
          </Image>
          <Image>
            <StaticImage src="../images/sdgs/sdg9.png" alt="sdgs9" />
          </Image>
          <Image>
            <StaticImage src="../images/sdgs/sdg10.png" alt="sdgs10" />
          </Image>
        </Row>
      </Colomn>
      <ImageWrapper view={view}>
        <StaticImage src="../images/service/3step.png" alt="3 step wallet" />
      </ImageWrapper>
    </Wrapper>
  )
}

const DX = ({ view }) => {
  if (view === "MOBILE") {
    return (
      <MWrapper>
        <Title en="DX" ja="DX" view={view} />
        <ImageWrapper view={view}>
          <StaticImage src="../images/service/m_dx.png" alt="dx" />
        </ImageWrapper>
        <TextWrapper>
          <Text view={view}>
            複数ステークホルダー間で安全に情報を共有できるシステムの構築製造業メーカの物流部門と配送業者間における手配情報や配送情報を追跡できる仕組みをブロックチェーン技術を活用して構築し、輸送事故の防止や業務の効率化に貢献しています。
          </Text>
        </TextWrapper>
      </MWrapper>
    )
  }
  return (
    <Wrapper reverse={true}>
      <Colomn>
        <Title en="DX" ja="DX" view={view} />
        <Text view={view}>
          複数ステークホルダー間で安全に情報を共有できるシステムの構築
        </Text>
        <Text view={view}>
          製造業メーカの物流部門と配送業者間における手配情報や
        </Text>
        <Text view={view}>
          配送情報を追跡できる仕組みをブロックチェーン技術を活用して
        </Text>
        <Text view={view}>
          構築し、輸送事故の防止や業務の効率化に貢献しています。
        </Text>
      </Colomn>
      <ImageWrapper view={view}>
        <StaticImage src="../images/service/dx.png" alt="dx" />
      </ImageWrapper>
    </Wrapper>
  )
}

const PLM1 = ({ view }) => {
  if (view === "MOBILE") {
    return (
      <MWrapper>
        <Title en="Product" ja="PLMシステム" view={view} />
        <ImageWrapper view={view}>
          <StaticImage src="../images/service/m_PLM.png" alt="PLM" />
        </ImageWrapper>
        <TextWrapper>
          <Text view={view}>
            PLM(Product Lifecycle
            Management)は、製造業などのものづくりに携わる企業にとって必須の基幹システムです。PLMでは、企画・設計・製造・調達・保守といった製品ライフサイクル全体を通じて発生するBOMやCAD情報など情報を集約し、企業の製品開発力や企業競争力を強化することを目的としています。
          </Text>
        </TextWrapper>
      </MWrapper>
    )
  }
  return (
    <Wrapper reverse={false}>
      <Colomn>
        <Title en="Product" ja="PLMシステム" view={view} />
        <Text view={view}>
          PLM(Product Lifecycle Management)は、製造業などの
        </Text>
        <Text view={view}>
          ものづくりに携わる企業にとって必須の基幹システムです。
        </Text>
        <Text view={view}>PLMでは、企画・設計・製造・調達・保守といった</Text>
        <Text view={view}>
          製品ライフサイクル全体を通じて発生するBOMやCAD情報など
        </Text>
        <Text view={view}>
          情報を集約し、企業の製品開発力や企業競争力を強化することを
        </Text>
        <Text view={view}>目的としています。</Text>
      </Colomn>
      <ImageWrapper view={view}>
        <StaticImage src="../images/service/PLM.png" alt="PLM" />
      </ImageWrapper>
    </Wrapper>
  )
}
const PLM2 = ({ view }) => {
  if (view === "MOBILE") {
    return (
      <MWrapper>
        <Title en="Product" ja="お客様へ" view={view} />
        <ImageWrapper view={view}>
          <StaticImage src="../images/service/m_okya.png" alt="PLM" />
        </ImageWrapper>
        <TextWrapper>
          <Text view={view}>
            当社では、大手自動車メーカーをはじめ航空機半導体製造メーカー、医療機器メーカーなどさまざまなお客様に対して
            設計から製造領域におけるBOM管理/CAD管理、文書管理、変更管理の効率化についてのさまざまな支援を行っています。
          </Text>
        </TextWrapper>
      </MWrapper>
    )
  }
  return (
    <Wrapper reverse={true}>
      <Colomn>
        <Title en="Product" ja="お客様へ" view={view} />
        <Text view={view}>当社では、大手自動車メーカーをはじめ航空機</Text>
        <Text view={view}>半導体製造メーカー、医療機器メーカーなど</Text>
        <Text view={view}>
          さまざまなお客様に対して 設計から製造領域における
        </Text>
        <Text view={view}>
          BOM管理/CAD管理、文書管理、変更管理の効率化についての
        </Text>
        <Text view={view}>さまざまな支援を行っています。</Text>
      </Colomn>
      <ImageWrapper view={view}>
        <StaticImage src="../images/service/okyakusama.png" alt="PLM" />
      </ImageWrapper>
    </Wrapper>
  )
}
const PLM3 = ({ view }) => {
  if (view === "MOBILE") {
    return (
      <MWrapper>
        <Title en="Product" ja="当社について" view={view} />
        <ImageWrapper view={view}>
          <StaticImage src="../images/service/m_tousya.png" alt="PLM" />
        </ImageWrapper>
        <TextWrapper>
          <Text view={view}>
            当社では、長年の製造業の基幹システムの大規模システムの設計・開発の知見を活かし、PLMの導入や開発の支援を行っています。特に、独Siemens社のTeamcenterや米Aras社のAras
            Innovatorなど大規模PLMパッケージシステムの導入の支援が主な活動です。また、PLM事業を通じて得た企業の課題は、企業の課題は、当社のブロックチェーン事業へのフィードバックとして活かされています。
          </Text>
        </TextWrapper>
      </MWrapper>
    )
  }
  return (
    <Wrapper reverse={false}>
      <Colomn>
        <Title en="Product" ja="当社について" view={view} />
        <Text view={view}>
          当社では、長年の製造業の基幹システムの大規模システムの
        </Text>
        <Text view={view}>設計・開発の知見を活かし、</Text>
        <Text view={view}>PLMの導入や開発の支援を行っています。</Text>
        <Text view={view}>特に、独Siemens社のTeamcenterや</Text>
        <Text view={view}>
          米Aras社のAras Innovatorなど大規模PLMパッケージシステム
        </Text>
        <Text view={view}>の導入の支援が主な活動です。また、PLM事業を</Text>
        <Text view={view}>通じて得た企業の課題は、企業の課題は、当社の</Text>
        <Text view={view}>
          ブロックチェーン事業へのフィードバックとして活かされています。
        </Text>
      </Colomn>
      <ImageWrapper view={view}>
        <StaticImage src="../images/service/tousya.png" alt="PLM" />
      </ImageWrapper>
    </Wrapper>
  )
}

export default IndexPage

const Margin = styled("div")(({ view }) => ({
  marginLeft: view === "MOBILE" ? "24px" : "80px",
}))

const ImageWrapper = styled("div")(({ view }) => ({
  width: view === "PC" ? "560px" : view === "TABLET" ? "480px" : "100vw",
  height: view === "PC" ? "560px" : view === "TABLET" ? "480px" : "",
  marginBottom: view === "MOBILE" ? "24px" : "0px",
}))

const Contents = styled("div")(({ view }) => ({
  margin: view === "MOBILE" ? "0px" : "0px 320px",
  width: view === "PC" ? "1280px" : view === "TABLET" ? "900px" : "100%",
}))

const Wrapper = styled("div")(({ reverse }) => ({
  width: "100%",
  display: "flex",
  marginTop: "40px",
  marginBottom: "160px",
  flexDirection: reverse ? "row-reverse" : "row",
  justifyContent: "space-between",
  alignItems: "center",
}))
const MWrapper = styled("div")(({ reverse }) => ({
  width: "100%",
}))

const Colomn = styled("div")({
  display: "flex",
  flexDirection: "column",
  width: "720px",
  paddingLeft: "80px",
})

const Text = styled("div")(({ view }) => ({
  color: Color.black,
  fontFamily: "Noto Sans JP",
  fontSize: view === "PC" ? "20px" : "14px",
  lineHeight: "2",
}))

const TextWrapper = styled("div")({
  margin: "0px 24px",
})

const Row = styled("div")({
  display: "flex",
  marginTop: "24px",
  "> div": {
    marginRight: "24px",
  },
})

const Image = styled("div")({
  width: "64px",
  height: "64px",
})

const Link = styled("a")(({ view }) => ({
  textDecoration: "none",
  fontFamily: "Noto Sans JP",
  fontSize: view === "PC" ? "20px" : "14px",
  lineHeight: "2",
  fontWeight: 700,
  ":hover": {
    color: Color.orange,
  },
}))

const IconWrapper = styled("span")({
  width: "20px",
  height: "20px",
  marginTop: "-4px",
})

const lineAnim = keyframes({
  "0%": {
    width: "0%",
  },
  "100%": {
    width: "100%",
  },
})

const T = styled("div")({
  marginRight: "24px",
  fontFamily: "Noto Sans JP",
  ":after": {
    content: '""',
    background: Color.white,
    display: "block",
    height: "2px",
    width: "100%",
  },
  ":hover": {
    ":after": {
      background: Color.orange,
      width: "100%",
      animation: `${lineAnim} 1s ease forwards`,
    },
  },
})

import styled from "@emotion/styled"
import * as React from "react"

const Title = ({ en, ja }) => {

  const [view, setView] = React.useState('PC')

  React.useEffect(() => {
    const w = window.innerWidth
    setView((() => {
      if (1440 < w) return "PC"
      else if (960 < w) return "TABLET"
      else return "MOBILE"
    })())
  })
  return (
    <Wrap view={view}>
      <English view={view}>{en}</English>
      <Japanese view={view}>{ja}</Japanese>
    </Wrap>
  )
}

export default Title

const Wrap = styled('span')(({view}) => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '40px',
  marginTop: view === 'MOBILE' ? '40px' : '0px',
  marginLeft: view === 'MOBILE' ? '24px' : '0px'
}))

const English = styled('div')(({view}) => ({
  WebkitTextStroke: '1px #E68011',
  color: 'white',
  fontSize: view === 'PC' ? '160px' : view === 'TABLET' ? '80px' : '40px',
  fontFamily: 'Roboto',
  fontFeatureSettings: '"palt" 1',
}))

const Japanese = styled('div')(({view}) => ({
  fontFeatureSettings: '"palt" 1',
  fontFamily: 'Noto Sans JP',
  color: '#161616',
  fontSize: view === 'PC' ? '40px' :  view === 'TABLET' ? '20px' : '14px',
  marginTop: view === 'PC' ? '-70px' : view === 'TABLET' ? '-35px' : '-10px',
  marginLeft: view === 'MOBILE' ? '0px' : '40px'
}))
import styled from "@emotion/styled"
import * as React from "react"
import { Color } from "../../../util/Color"

const LeftTitle = ({ en, ja, id, view }) => {
  return (
    <Wrap id={id} view={view}>
      <English view={view}>{en}</English>
      <Japanese view={view}>{ja}</Japanese>
    </Wrap>
  )
}

export default LeftTitle

const Wrap = styled('span')(({view}) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  marginBottom: view === 'MOBILE' ? '16px' : '32px',
  marginTop: view === 'MOBILE' ? '60px' : '240px',
}))

const English = styled('div')(({view}) => ({
  color: Color.orange,
  fontSize: view === 'PC' ? '160px' : view === 'TABLET' ? '80px' : '40px',
  fontFamily: 'Roboto',
  fontFeatureSettings: '"palt" 1',
}))

const Japanese = styled('div')(({view}) => ({
  fontFamily: 'Noto Sans JP',
  fontFeatureSettings: '"palt" 1',
  color: '#161616',
  fontSize: view === 'PC' ? '48px' : view === 'TABLET' ? '24px' : '14px',
  marginTop: view === 'PC' ? '-65px' : view === 'TABLET' ? '-40px' : '0px',
  marginLeft: view === 'MOBILE' ? '0px' : '32px'
}))